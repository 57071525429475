import React, { useCallback, useState } from 'react';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import formatInputNumber from '../../utils/formatInputNumber';
import { getNumberValue } from '../../utils/getNumberValue';
import FormInput from '../shared/FormControl/Input';

const NoShowForm = ({ onNext }) => {
  const [amount, setAmount] = useState('');
  const [numNoShow, setNumNoShow] = useState('');
  const [amountErr, setAmountErr] = useState('');
  const [noShowErr, setNoShowErr] = useState('');
  const [error, setError] = useState('');

  const handleAmount = useCallback(value => {
    !/^\$\d+(,\d+)*$/.test(value.trim())
      ? setAmountErr('Invalid amount!')
      : setAmountErr('');

    setAmount(value);
  }, []);

  const handleNoShow = useCallback(({ target }) => {
    !/^[0-9]+$/.test(target.value.trim())
      ? setNoShowErr('Invalid number!')
      : setNoShowErr('');

    setNumNoShow(target.value);
  }, []);

  const handleNext = useCallback(() => {
    if (amount && numNoShow) {
      setError('');
      onNext(getNumberValue(amount), parseInt(numNoShow, 10));
    } else {
      setError('Please complete all the required fields!');
    }
  }, [onNext, amount, numNoShow]);

  return (
    <div className="noshow-calculator">
      <h2 className="form-title">Customer No-Show Loss Calculator</h2>

      <div className="form-content">
        <label htmlFor="amount">What is your average repair order cost?</label>
        <FormInput
          id="amount"
          name="amount"
          placeholder="Enter amount ($)"
          error={amountErr}
          value={amount}
          onChange={e => handleAmount(formatInputNumber(e.target.value))}
        />

        <label htmlFor="num-no-shows">
          What is your average number of customer no-shows per week?
        </label>
        <FormInput
          id="num-no-shows"
          name="num-no-shows"
          placeholder="Enter average number of no-shows"
          type="tel"
          error={noShowErr}
          value={numNoShow.replace(/\D*/g, '')}
          onChange={handleNoShow}
        />

        {error && <p className="text-danger fs-16">{error}</p>}

        <CallToAction
          variant="secondary"
          size="lg"
          className="w-100 mb-3"
          value="Next"
          customHandleClick={handleNext}
        />
      </div>

      <div className="notice">
        <p>
          This No-Show Loss Calculator is provided for informational purposes
          only, and is not intended to and does not replace professional
          financial guidance. Please consult a financial professional.
        </p>
      </div>
    </div>
  );
};

export default NoShowForm;
